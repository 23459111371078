import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import { compose, createEventHandler, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import ChipInput from "material-ui-chip-input";

// new version: https://www.npmjs.com/package/material-ui-chip-input
// old version: https://github.com/TeamWertarbyte/material-ui-chip-input/tree/legacy

const enhancer = compose(
  mapPropsStream((propsStream) => {
    const { handler: nodeRef, stream: nodeRefStream } = createEventHandler();

    const sideEffectsStream = Observable.merge(
      propsStream
        .pluck("focus")
        .filter(Boolean)
        .distinctUntilChanged()
        .combineLatest(
          nodeRefStream.map(
            fp.get(["autoComplete", "refs", "searchTextField", "input"]),
          ),
        )
        .delay(100)
        .do(([, input]) => input.focus()),
    ).startWith(null);

    return propsStream.combineLatest(sideEffectsStream, (props) => ({
      ...props,
      nodeRef,
    }));
  }),
);

ChipTextField.propTypes = {
  nodeRef: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxItems: PropTypes.number,
  fullWidth: PropTypes.bool,
  hintText: PropTypes.string,
  hintStyle: PropTypes.object,
  errorText: PropTypes.string,
  error: PropTypes.node,
  touched: PropTypes.bool,
  invalid: PropTypes.bool,
  addOnBlur: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  style: PropTypes.object,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
};

ChipTextField.defaultProps = {
  onBlur: fp.noop,
  onFocus: fp.noop,
  addOnBlur: true,
  clearOnBlur: false,
  maxItems: Infinity,
  variant: "standard",
};

const compactUniq = fp.flow(fp.compact, fp.uniq);
const joinLines = fp.flow(fp.split("\n"), fp.map(fp.trim), compactUniq);

function ChipTextField(props) {
  const trimChipsSize = fp.flow(compactUniq, fp.takeLast(props.maxItems));
  const concatChip = fp.flow(fp.concat(props.value), trimChipsSize);
  return (
    <ChipInput
      style={props.style}
      variant={props.variant}
      ref={props.nodeRef}
      fullWidth={props.fullWidth}
      disableUnderline={props.disableUnderline}
      // clearOnBlur={props.addOnBlur || props.clearOnBlur}
      clearInputValueOnChange={props.addOnBlur || props.clearOnBlur}
      defaultValue={props.value}
      value={props.value}
      hintStyle={props.hintStyle}
      // inputStyle={props.inputStyle}
      // chipContainerStyle={props.chipContainerStyle}
      // errorText={props.touched && props.invalid ? props.error : props.errorText}
      helperText={
        props.touched && props.invalid ? props.error : props.errorText
      }
      placeholder={props.hintText}
      // label={props.label}
      onBlur={fp.flow(
        (event) => (props.addOnBlur ? event.target.value : null),
        fp.trim,
        concatChip,
        props.onBlur,
      )}
      onFocus={fp.flow(fp.noop, props.onFocus)}
      onChange={fp.flow(concatChip, props.onChange)}
      onAdd={fp.flow(concatChip, props.onChange)}
      onDelete={fp.flow(
        fp.rearg([1, 0], fp.pull)(props.value),
        trimChipsSize,
        props.onChange,
      )}
      onPaste={fp.flow(
        (event) =>
          fp.attempt(
            fp.flow(
              () => event.clipboardData.getData("Text"),
              joinLines,
              (lines) => {
                if (lines.length > 1) {
                  event.preventDefault();

                  return lines;
                }

                return null;
              },
            ),
          ),
        fp.cond([[fp.isArray, fp.flow(concatChip, props.onChange)]]),
      )}
    />
  );
}

export default enhancer(ChipTextField);
