import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

const CUSTOMER_SERVICE_TYPE_URL = `${API_ROOT_URL}/service_types`;
const CUSTOMER_SERVICE_TYPES_URL = `${API_V2_ROOT_URL}/customer/packages/services`;
const SERVICE_TYPE_URL = `${API_ROOT_URL}/admin/service_types`;
const SERVICE_TYPE_LIST_URL = `${API_ROOT_URL}/admin/service_types`;
const SERVICE_TYPE_ITEM_URL = `${API_ROOT_URL}/admin/service_types/%s`;
const SERVICE_TYPE_CHOOSE_URL = `${API_ROOT_URL}/service_types/choose`;
const SERVICE_TYPES_CATEGORY_URL = `${API_ROOT_URL}/admin/service_types/category/all`;
const SERVICE_TYPE_BY_CODE_URL = `${API_ROOT_URL}/admin/service_types/code`;

const parseArrayResponse = fp.flow(fp.get("data"), fp.toArray, fromJS);

export const getServiceTypesListCustomer = () =>
  api.get(CUSTOMER_SERVICE_TYPE_URL);
export const getCustomerServiceTypes = params =>
  api.get(CUSTOMER_SERVICE_TYPES_URL, { params });

export const getServiceTypesList = (request: DataListFilter) =>
  api.getStream(SERVICE_TYPE_LIST_URL, { params: request.getDefinedValues() });

export const getServiceType = id =>
  api.getStream(sprintf(SERVICE_TYPE_ITEM_URL, id));

export const createServiceType = values =>
  api.post(SERVICE_TYPE_URL, { body: values });

export const updateServiceType = values =>
  api.put(SERVICE_TYPE_URL, { body: values });

export const deleteServiceType = id =>
  api.delete(sprintf(SERVICE_TYPE_ITEM_URL, id));

const cachedServiceTypes = new ObservableCache({
  loader: () => api.get(SERVICE_TYPE_CHOOSE_URL).then(parseArrayResponse),
});

const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(SERVICE_TYPE_CHOOSE_URL, {
      params: request.getDefinedValues(),
    }),
});

const predictionsCategoryCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: () => api.getStream(SERVICE_TYPES_CATEGORY_URL),
});

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: request =>
    api.getStream(SERVICE_TYPE_BY_CODE_URL, {
      params: request.getDefinedValues(),
    }),
});

const itemCacheByID = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(SERVICE_TYPE_ITEM_URL, id)),
});

export const getServiceTypes = () => cachedServiceTypes.get();

export const getServiceTypePredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const getServiceTypeCategoryPredictions = () =>
  predictionsCategoryCache.get();

export const getCachedServiceType = request =>
  itemCache
    .get(request)
    .takeLast(1)
    .map(fp.flow(fp.get("data.list"), fromJS));

export const getCachedServiceTypeById = id =>
  itemCacheByID
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));

export const getServiceTypes2 = () => api.get(SERVICE_TYPE_CHOOSE_URL);
